/* You can add global styles to this file, and also import other style files */

html,
body {
  font-family: "Montserrat", sans-serif;
}

/* Para separar el content del menú superior */
.content {
  padding-top: 15px;
  background-color: #dde5e7;
}

.content-wrapper {
  background-color: #dde5e7;
}

/* Para centrar el texto en el template caption en la p-table */
.my-center-text {
  text-align: center;
  width: 100%;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.ui-helper-clearfix {
  margin-top: 15px;
}

/* Botón  para descarga de csv*/
.download-button {
  margin-left: 20px !important;
  vertical-align: baseline !important;
}

/* Bordes */
p.dotted {
  border-style: dotted;
}
div.dashed {
  border-style: dashed;
  border-width: medium;
}
p.solid {
  border-style: solid;
}
p.double {
  border-style: double;
}
p.groove {
  border-style: groove;
}
p.ridge {
  border-style: ridge;
}
p.inset {
  border-style: inset;
}
p.outset {
  border-style: outset;
}
p.none {
  border-style: none;
}
p.hidden {
  border-style: hidden;
}
p.mix {
  border-style: dotted dashed solid double;
}

.p-button {
  background-color: var(--primary-color);
  border: var(--primary-color);
  font-weight: bold;
}

.ng-dirty .ng-invalid {
  border-color: #ff7226 !important;
}
.text-danger-orange {
  color: #ff7226 !important;
}

.p-datatable .p-datatable-tbody > tr {
  background-color: #dde5e7;
}

/* estilos de los inputs con label con espaciado */
/* .basic-input-container {
  max-width: 350px;
}

.long {
  max-width: 550px;
}

.basic-input-container label {
  margin-right: 10px;
}

.basic-input-container div {
  display: flex;
  align-items: center;
}

.basic-input-container div input {
  max-height: 20px;
  margin-left: 10px;
}

.basic-input-container div input:disabled {
  border: 1px solid grey;
}

.basic-input-container div select {
  max-height: 100%;
  margin-left: 10px;
}

.basic-input-container div textarea {
  height: 200px;
  width: 350px;
  margin-left: 10px;
} */

.long_input {
  width: -webkit-fill-available;
}

.big_input {
  width: -webkit-fill-available;
  height: 200px;
}

.basic-input-container .date-number-input {
  max-height: 20px;
  margin-left: 10px;
}

/* estilos de colores y bordes de los inputs */
/* .card, .card-secondary {
    background-color: #dde5e7;
  } */
.content {
  background-color: #dde5e7;
}

input,
select,
textarea {
  border-color: #fff;
  border: 0px;
}
.boton-verde {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  outline: none;
  /* box-shadow: 2px 2px 4px grey;  */
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.boton-centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dropdown {
  /* border: 0px;
  border-radius: 0;
  font-size: small; */
  width: -webkit-fill-available;
  /* max-height: 20px; */
}

.full-height {
  .p-dropdown {
    height: 100%;
  }

  .p-dropdown .p-dropdown-label {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.sapareted-label-button {
  display: flex;
  width: 550px;
  justify-content: space-between;
}

.whiteBack {
  background-color: white;
}

.p-inputnumber-input {
  width: 100%;
}

.back-cnb-gray {
  background-color: #dde5e7;
}

/* estilos de las tablas de primeng */

.p-datatable .p-datatable-header {
  background-color: #dde5e7;
}

.p-dialog {
  background-color: #dde5e7;
}

.p-dialog-draggable .p-dialog-header {
  background-color: #dde5e7;
}

.p-dialog .p-dialog-content {
  background-color: #dde5e7;
}

.p-dialog .p-dialog-footer {
  background-color: #dde5e7;
}

.p-toolbar {
  background-color: #dde5e7;
}

.p-datatable .p-datatable-tbody > tr > td {
  background-color: #dde5e7;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #dde5e7;
}

.blank > .p-datatable .p-datatable-tbody > tr > td {
  background-color: #ffff;
}

.blank > .p-datatable .p-datatable-thead > tr > th {
  background-color: #ffff;
}

.blank > .p-datatable .p-datatable-tbody > tr {
  background-color: #ffff;
}

.table-no-border > .p-datatable .p-datatable-tbody > tr > td {
  border: none;
}

.p-paginator {
  background-color: #dde5e7;
}

.p-paginator .p-paginator-current {
  background-color: #dde5e7;
}

.p-paginator .p-dropdown {
  background-color: #dde5e7;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #dde5e7;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: #dde5e7;
}

.not-overlay .p-overlay {
  max-width: 100%;
}

.number-table-index {
  background-color: #f0a35d;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.mxw-12 {
  max-width: 12rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.block {
  display: block;
}

.over-visible > .p-dialog-content {
  overflow: visible !important;
}

.toolbar-sin-bordes {
  border: none;
  padding: 0;
}

.p-dropdown-panel {
  max-width: 50vw;
}

.nav-link.deshabilitado {
  pointer-events: none;
  opacity: 0.5;
}

.link-in-page {
  /* text-decoration: underline; */
  font-weight: bold;
}

.p-image-preview-container > img {
  max-width: 100% !important;
  max-height: 100% !important;
}

/* 
.card {
  background-color: #dde5e7;
  border: 0px solid #000;
  box-shadow: none;
}

.card-body {
  background-color: #dde5e7;
  border: 0px solid #000;
  box-shadow: none;
}

.card-header {
  background-color: #dde5e7 !important;
  color: black !important;
}

.card-footer {
  background-color: #dde5e7;
} */

.transparent-background {
  background: linear-gradient(to bottom, transparent, #dde5e7 100%);
}

/* .p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid rgba(0, 0, 0, 0.125) ;
  border-width: 0 0 1px 0 ;
}

.p-datatable .p-datatable-thead > tr > th {
  border: 1px solid rgba(0, 0, 0, 0.125) ;
  border-width: 0 0 1px 0 ;
  background-color: #dae1e4;
  padding-top: 1rem;
  padding-bottom: 1rem;
} */

.custom-table .p-paginator {
  border-bottom: solid 1px #c4c9ca;
}
.custom-table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 0 0 1px 0;
}
.custom-table tr th,
.custom-table tr td {
  border: none !important;
}
.custom-table tr:hover td {
  background-color: #d7dadb;
  transition: background-color 0.2s;
}
.custom-table td .p-button.p-button-icon-only.p-button-rounded {
  height: 1.8rem;
  width: 1.8rem;
}
.custom-table td .p-button.p-button-sm .p-button-icon {
  font-size: 0.9rem;
}
.custom-table .p-datatable .p-datatable-tbody > tr > td {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.custom-table th {
  background-color: hsl(190, 5%, 80%) !important; 
}

.full-link {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  opacity: 0;
}
a:has(> .full-link) div:has(> .full-link), td:has(> .full-link), button.pi-element:has(> .full-link) {
  position: relative;
}
a:has(> .full-link:focus) {
  box-shadow: inset var(--focus-ring);
  font-weight: 700;
  color: var(--primary-color);
}

.file-upload-w-full > .p-component > .p-button.p-fileupload-choose {
  width: 100% !important;
  min-width: 20rem;
}

.p-skeleton {
  background-color: #dadada;
}